import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Maintain Version Details" } }, [_c("select-input", { key: "country", attrs: { "label": "Country", "reference": "web-analytics.common.countries", "placeholder": "Select Country", "source": "country", "rules": "required", "span": 12, "option-filter": _vm.filterCountry, "value": _vm.country, "disabled": true, "form-item": "" } }), _c("text-input", { key: "sortOrder", attrs: { "rules": "required|numeric", "label": "Sort Order", "span": 12, "placeholder": "Type Sort Order", "form-item": "" } }), _c("select-input", { key: "version", attrs: { "label": "Version", "reference": "web-analytics.common.dgp-versions", "placeholder": "Select Version", "source": "version", "source-label": "versionDesc", "default-active-first-option": "", "rules": "required", "span": 12, "form-item": "" } }), _c("text-input", { key: "alias", attrs: { "label": "Alias", "placeholder": "Type Alias", "rules": "required", "span": 12, "form-item": "" } }), _c("date-picker", { key: "finYear", attrs: { "year-only": true, "label": "Fin Year", "placeholder": "Select Year", "format-date": "YYYY", "rules": "required", "span": 12, "form-item": "" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateMaintainVersionDetails",
  inject: ["crud"],
  computed: {
    country() {
      return this.crud.getFilters().get("Country").value;
    }
  },
  created() {
    this.storeValue("country", this.country);
  },
  methods: {
    filterCountry(option) {
      return option.countryId < 3;
    },
    storeValue(resourceKey, value) {
      this.createForm = {
        ...this.createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateMaintainVersionDetails = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.dgp-versions", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.version-details", "api-url": _vm.apiUrl, "create": _vm.CreateMaintainVersionDetails, "redirect-route": "/web-analytics/maintain-version-details" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateMaintainVersionDetails",
  data() {
    return {
      CreateMaintainVersionDetails,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
